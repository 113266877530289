import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DoctypeaddService {
    url = environment.Url;

    constructor(private http:HttpClient,
      private cookie: CookieService) { }

     // doctype add
     doctypeAdd(data){
       const httpheaders = new HttpHeaders({
         'Content-Type': [],
         'Accept': 'application/json',
        //  'Token': this.cookie.get('Token')
        'Token': localStorage.getItem('Token')
       });
       return this.http.post(`${this.url}/docTypeAdd`,data,{
         headers: httpheaders,
         observe: 'response'
       });
     }


     fileupload(data: File[], type) {
     
      ////debugger
      var formData = new FormData();
      Array.from(data).forEach(f => formData.append('file', f),
        formData.append('user', type + '|' + 'NotReplace'))
        
      // formData.append('replacefilename', '');
      const httpheaders = new HttpHeaders({
        // 'Token': this.cookie.get('Token')
        'Token': localStorage.getItem('Token')
      });
      return this.http.post(`${this.url}/fileUpload`, formData, {
        headers: httpheaders,
        observe: 'response'
      });
    }
  }


