import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehiclemodeladdService {

      url = environment.Url;
      Url1=environment.Url1;
      constructor(private http:HttpClient,
        private cookie: CookieService) { }

       // vehicle model add
       vehiclemodelAdd(data){
         const httpheaders = new HttpHeaders({
           'Content-Type': [],
           'Accept': 'application/json',
          //  'Token': this.cookie.get('Token')
           'Token': localStorage.getItem('Token'),
          'ngrok-skip-browser-warning' : 'true'
         });
         return this.http.post(`${this.url}/vehicleModelAdd`,data,{
           headers: httpheaders,
           observe: 'response'
         });
       }
     // get vehicle brand
     getvehiclebrand(){
       const httpheaders = new HttpHeaders({
         'Content-Type': [],
         'Accept': 'application/json',
        //  'Token': this.cookie.get('Token')
         'Token': localStorage.getItem('Token'),
            'ngrok-skip-browser-warning' : 'true'
       });
       return this.http.get(`${this.url}/vehicleBrand`,{
         headers: httpheaders,
         observe: 'response'
       });
     }



     addSubcategory(brandId: any): Observable<any> {
      const httpheaders = new HttpHeaders({
        'Content-Type': [],
        'Accept': 'application/json',
       //  'Token': this.cookie.get('Token')
        'Token': localStorage.getItem('Token'),
         'ngrok-skip-browser-warning' : 'true'
      });
      return this.http.post(`${this.url}/vehicleModel`,brandId,{
        headers: httpheaders,
         observe: 'response'
      });
    }

    

    


    ServiceType(params:any){
      const httpheaders = new HttpHeaders({
        'Content-Type': [],
        'Accept': 'application/json',
       //  'Token': this.cookie.get('Token')
        'Token': localStorage.getItem('Token'),
         'ngrok-skip-browser-warning' : 'true'
      });
      return this.http.post(`${this.url}/serviceType`,params,{
        headers: httpheaders,
        observe: 'response'
      });
    }


     

     vehicleadding(param: { vehicleId: string; }){
      const httpheaders = new HttpHeaders({
        'Content-Type': [],
        'Accept': 'application/json',
       //  'Token': this.cookie.get('Token')
        'Token': localStorage.getItem('Token'),
         'ngrok-skip-browser-warning' : 'true'
      });
      return this.http.post(`${this.url}/addVehicle`,{
        headers: httpheaders,
        observe: 'response'
      });
    }

    VehicleAdd(params:any){
      const httpheaders = new HttpHeaders({
        'Content-Type': [],
        'Accept': 'application/json',
       //  'Token': this.cookie.get('Token')
        'Token': localStorage.getItem('Token'),
         'ngrok-skip-browser-warning' : 'true'
      });
      return this.http.post(`${this.url}/addVehicle`,params,{
        headers: httpheaders,
        observe: 'response'
      });
    }

  }
